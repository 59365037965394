import { Link } from 'gatsby';
import React from 'react';
import FlexCenter from '../components/flex-center';
import SEO from '../components/seo';

const noFollowMetaTag = [
  {
    name: 'robots',
    content: 'noindex,nofollow',
  },
];

const Root: React.FC = () => (
  <>
    <SEO meta={noFollowMetaTag} title={'123 Landing Sites'} />
    <FlexCenter>
      <main>
        <h2>Landing Pages</h2>
        <ul>
          <li>
            <Link to="/br/landings/cotar-seguro-de-carro">Landing de Pauta Autos BR</Link>
          </li>
          <li>
            <Link to="/mx/landings/cotizar-seguro-de-auto">Landing de Pauta Autos MX</Link>
          </li>
          <li>
            <Link to="/uy/landings/cotizar-seguro-de-auto">Landing de Pauta Autos UY</Link>
          </li>
          <li>
            <Link to="/mx/seguros/auto">Landing Orgánica MX</Link>
          </li>
          <li>
            <Link to="/ar/landings/seguros-para-comercio">Landing de Pauta Comercio AR</Link>
          </li>
          <li>
            <Link to="/cl/landings/seguro-automotriz/soap">Landing Promoción SOAP 2021 Chile</Link>
          </li>
          <li>
            <Link to="/ar/landings/seguros-para-camiones">Landing de Pauta Camiones AR</Link>
          </li>
          <li>
            <Link to="/ar/plus/solicitar-acceso">Landing de Pauta 123plus AR</Link>
          </li>
          <li>
            <Link to="/co/plus/solicitar-acceso">Landing de Pauta 123plus CO</Link>
          </li>
          <li>
            <Link to="/cl/plus/solicitar-acceso">Landing de Pauta 123plus CL</Link>
          </li>
          <li>
            <Link to="/br/plus/solicitar-acceso">Landing de Pauta 123plus BR</Link>
          </li>
        </ul>
        <h2>Partner Pages</h2>
        <ul>
          <li>
            <Link to="/br/partner/loggi">Landing de Loggi-Suhai BR</Link>
          </li>
          <li>
            <Link to="/br/partner/serasa">Landing de Serasa-Prudential</Link>
          </li>
          <li>
            <Link to="serasa">Landing de Serasa-Prudential (parceiros)</Link>
          </li>
        </ul>
      </main>
    </FlexCenter>
  </>
);

export default Root;
